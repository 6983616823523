import BriefcaseIcon from "@heroicons/react/24/outline/BriefcaseIcon";
import BuildingOfficeIcon from "@heroicons/react/24/outline/BuildingOfficeIcon";
import ChevronDownIcon from "@heroicons/react/24/solid/ChevronDownIcon";
import ChevronUpIcon from "@heroicons/react/24/solid/ChevronUpIcon";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import SparklesIcon from "@heroicons/react/24/solid/SparklesIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import { Chip, OutlinedInput } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import { default as Link } from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import { PopupButton } from "@typeform/embed-react";
import styled from "styled-components";
import Color from "../../../../assets/colors";
import { FontSize } from "../../../../assets/font";
import MYFlag from "../../../../public/images/MY.svg";
import SGFlag from "../../../../public/images/SG.svg";

// Menu List Component //
export const MenuListStyled = styling(MenuList)({
  overflow: "hidden !important",
  textOverflow: "ellipsis !important",
});

// Paper Component //
export const PaperStyled = styling(Paper)({
  overflow: "hidden",
  textOverflow: "ellipsis !important",
});

// Menu Item Components //
export const MenuItemStyled = withTheme(styled(MenuItem)`
  && {
    font-weight: ${(props) => (props.$font_bold ? "bold" : null)};
    cursor: ${(props) => (props.$cursor ? "default" : null)};
    width: 100%;
    height: 2rem;
    padding: 0 0 0 15px;
    justify-content: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`);

export const NavbarLinksStyled = styling(Button)((props) => ({
  fontSize: "16px",
  fontWeight: "400",
  textTransform: "capitalize",
  whiteSpace: "nowrap",
  margin: "0 0 0 1.5rem",
  textDecoration: "none",

  "&:hover": {
    fontWeight: "700",
    backgroundColor: "transparent",

    textDecoration: "underline",
    textUnderlineOffset: "1.3rem",
    textDecorationThickness: "6px",

    textDecoration: "none",
    transition: "all 0.125s ease-in-out",
  },

  ...(props.$active && {
    fontWeight: "700",
    backgroundColor: "transparent",

    textDecoration: "underline",
    textUnderlineOffset: "1.3rem",
    textDecorationThickness: "6px",
  }),

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "12px",
    margin: "0",
    display: props.$type === "for-you" ? "flex" : "none",
  },

  [props.theme.breakpoints.down("tablet")]: {
    display: "none",
  },
}));

export const JobsIcon = styling(BriefcaseIcon)((props) => ({
  height: "24px",
  width: "24px",

  [props.theme.breakpoints.down("sm")]: {
    height: "18px",
    width: "18px",
  },
}));

export const CompanyIcon = styling(BuildingOfficeIcon)((props) => ({
  height: "24px",
  width: "24px",

  [props.theme.breakpoints.down("sm")]: {
    height: "18px",
    width: "18px",
  },
}));

export const BadgeStyled = styling(Badge)({
  "& .MuiBadge-badge ": {
    fontSize: "0.6rem",
    fontWeight: "300",
    letterSpacing: "1.5px",

    borderRadius: "4px",
    padding: "0 0.215rem",
    margin: "0 0 1rem 0",
    height: "17px",
  },
});

export const HomepageButtonContainer = styling(Stack)({
  height: "3.5rem",
  zIndex: "-1",

  borderTop: `1.5px solid ${Color.black}`,
  borderBottom: `1.5px solid ${Color.black}`,
  background: `${Color.lightHiredlyPurple}`,
});

export const HomepageButton = styling(Link)((props) => ({
  cursor: "pointer",
  width: "100%",
  height: "3.5rem",
  color: `${Color.hiredlyPurple}`,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",

  fontSize: "0.75rem",
  fontWeight: "500",

  letterSpacing: "0.09375rem",
  textTransform: "uppercase",
  textDecoration: "none",
  borderRight: props.jobs_button && `1.5px solid ${Color.black}`,

  [props.theme.breakpoints.down("sm")]: {
    gap: "0.5rem",
  },
}));

// NEW IMPLEMENTATION
export const AppbarStyled = styling(AppBar)((props) => ({
  backgroundColor: props.$employer ? "white" : "hsla(0, 0%, 100%, 0.8)",
  backdropFilter: "blur(0.25rem)",
  height: "68px",

  [props.theme.breakpoints.down("lg")]: {
    height: props.$homepage === "true" ? "125px" : "68px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    boxShadow: "none",
    borderBottom: "1px solid lightgrey",
  },
}));

export const LogoStyled = styling("img")((props) => ({
  maxWidth: "132px",
  height: "20px",
  objectFit: "contain",
  paddingBottom: "5px",

  [props.theme.breakpoints.down("lg")]: {
    width: "110px",
    height: "32px",
    paddingBottom: "0px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    width: "128px",
    height: "37px",
  },
}));

export const VanillaGrid = styling(Grid)({
  //
});

export const TypographyStyled = styling(Typography)({
  //
});

export const ToolbarStyled = styling(Toolbar)((props) => ({
  display: "flex",
  justifyContent: "space-between",
  height: "68px",
  minHeight: "68px",

  "& .MuiToolbar-root": {
    minHeight: "68px",
  },

  [props.theme.breakpoints.between("tablet", "lg")]: {
    minHeight: "69px",
  },
}));

export const IconButtonStyled = styling(IconButton)({
  position: "relative",
});

export const MYCountryFlag = styling(MYFlag, {
  shouldForwardProp: (propName) => propName !== "isPopper",
})((props) => ({
  height: props.isPopper ? "12px" : "27px",

  [props.theme.breakpoints.down("lg")]: {
    height: props.isPopper ? "12px" : "22px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    height: props.isPopper ? "12px" : "18px",
  },
}));

export const SGCountryFlag = styling(SGFlag, {
  shouldForwardProp: (propName) => propName !== "isPopper",
})((props) => ({
  height: props.isPopper ? "12px" : "27px",

  [props.theme.breakpoints.down("lg")]: {
    height: props.isPopper ? "12px" : "22px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    height: props.isPopper ? "12px" : "18px",
  },
}));

export const CountryOptionName = styling(Typography)((props) => ({
  fontSize: "14px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "14px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "12px",
  },
}));

export const ChevronDownStyled = styling(ChevronDownIcon)((props) => ({
  color: Color.black,
  width: 24,
  height: 24,

  [props.theme.breakpoints.down("tablet")]: {
    width: 13,
    height: 13,
  },
}));

export const ChevronUpStyled = styling(ChevronUpIcon)((props) => ({
  color: Color.black,
  width: 24,
  height: 24,

  [props.theme.breakpoints.down("tablet")]: {
    width: 13,
    height: 13,
  },
}));

export const LoginButton = styling(Button)((props) => ({
  width: "100px",
  height: "40px",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  padding: "0 16px",
  borderRadius: "8px",
  textTransform: "none",
  color: Color.hiredlyPurple,
  border: `1px solid ${Color.hiredlyPurple}`,
  backgroundColor: Color.white,

  "&:hover": {
    color: Color.white,
    backgroundColor: Color.hiredlyPurple,
  },

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "12px",
    width: "80px",
    height: "32px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    padding: "0 24px",
    height: "24px",
    width: "60px",
    marginLeft: "20px",
    borderRadius: "100px",
    fontSize: "12px",
  },
}));

export const SignUpButton = styling(Button)((props) => ({
  width: "100px",
  height: "40px",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  padding: "0 16px",
  borderRadius: "8px",
  textTransform: "none",
  color: Color.white,
  backgroundColor: Color.hiredlyPurple,
  border: `1px solid ${Color.hiredlyPurple}`,

  "&:hover": {
    color: Color.hiredlyPurple,
    backgroundColor: Color.white,
  },

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "12px",
    width: "80px",
    height: "32px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    display: "none",
  },
}));

export const TextButtonStyled = styling(Button)((props) => ({
  textTransform: "none",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  color: Color.hiredlyPurple,
  display: "flex",
  whiteSpace: "nowrap",

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "12px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    display: "none",
  },
}));

export const SearchInputContainer = styling(OutlinedInput)((props) => ({
  padding: "6px 8px 6px 16px",
  borderRadius: "80px",
  width: "360px",
  border: `1px solid ${Color.secondaryGrey}`,
  marginTop: "10px",
  marginBottom: "10px",
  height: "48px",
  fontSize: "16px",
  fontFamily: "Inter",
  lineHeight: "22px",
  backgroundColor: Color.white,

  "&:hover": {
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.16)",
  },

  [props.theme.breakpoints.down("lg")]: {
    padding: "6px 3px 6px 16px",
    width: "257px",
    marginTop: "8px",
    marginBottom: "8px",
    height: "36px",
    fontSize: "13px",

    "& .MuiInputBase-input": {
      paddingLeft: "5px",
    },
  },

  [props.theme.breakpoints.down("tablet")]: {
    width: "192px",
    border: "none",
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.16)",

    "& fieldset": {
      border: "none",
    },
  },
}));

export const MagnifyingGlassIconStyled = styling(MagnifyingGlassIcon)(
  (props) => ({
    width: "20px",
    height: "20px",

    [props.theme.breakpoints.down("lg")]: {
      width: "16px",
      height: "16px",
    },
  })
);

export const LinkContainer = styling(Grid)((props) => ({
  padding: "15px 0px",
  display: "flex",

  ":hover": {
    backgroundColor: Color.lightGrey,
  },
}));

export const LinkStyled = styling("a")((props) => ({
  textDecoration: "none",
  fontSize: "16px",
  fontFamily: "Inter",
  color: Color.black,
  fontWeight: "700",
}));

export const SparkleIconStyled = styling(SparklesIcon)((props) => ({
  color: Color.accentYellow,
}));

export const DrawerLoginButton = styling(Button)((props) => ({
  flex: 1,
  height: "43px",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  padding: "12px 24px",
  borderRadius: "30px",
  textTransform: "none",
  color: Color.hiredlyPurple,
  border: `1px solid ${Color.hiredlyPurple}`,
  backgroundColor: Color.white,
  maxWidth: "150px",

  "&:hover": {
    color: Color.white,
    backgroundColor: Color.hiredlyPurple,
  },
}));

export const DrawerSignUpButton = styling(Button)((props) => ({
  flex: 1,
  height: "43px",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  padding: "12px 24px",
  borderRadius: "30px",
  textTransform: "none",
  color: Color.white,
  backgroundColor: Color.hiredlyPurple,
  border: `1px solid ${Color.hiredlyPurple}`,
  maxWidth: "150px",

  "&:hover": {
    color: Color.hiredlyPurple,
    backgroundColor: Color.white,
  },
}));

export const AvatarStyled = styling(Avatar)((props) => ({
  width: "35px",
  height: "35px",
  cursor: "pointer",
}));

export const PopperStyled = styling(Popper)((props) => ({
  //
}));

export const GrowStyled = styling(Grow)((props) => ({
  zIndex: 20,
  width: "210px",
  marginRight: "20px",
  // transformOrigin: "center top",
}));

export const CountryGrowStyled = styling(Grow)((props) => ({
  zIndex: 20,
  marginTop: "25px",
  marginRight: "20px",
}));

export const ClickAwayListenerStyled = styling(ClickAwayListener)((props) => ({
  //
}));

export const SearchSuggestionContainer = styling(Grid)((props) => ({
  color: Color.black,
  cursor: "pointer",
  padding: "5px 10px",
  borderRadius: "30px",
  width: "100%",

  ":hover": {
    backgroundColor: Color.lightGrey,
    cursor: "pointer",
  },
}));

export const SearchDropdownContainer = styling(Grid)((props) => ({
  borderRadius: "10px",
  backgroundColor: Color.white,
  boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)",
  width: "427px",
  padding: "20px",
  marginTop: "10px",
  position: "absolute",
  top: "50px",
  zIndex: "10px",

  [props.theme.breakpoints.down("lg")]: {
    width: "370px",
  },
}));

export const RecentSearchText = styling(Typography)((props) => ({
  fontSize: FontSize.desktopCaption,
  fontWeight: FontSize.bold,
  color: Color.black,
}));

export const ChipStyled = styling(Chip)((props) => ({
  backgroundColor: "rgba(81, 42, 204, 0.15)",
  padding: "5px 10px",
  margin: "10px 5px",
  cursor: "pointer",

  ":hover": {
    backgroundColor: "rgba(81, 42, 204, 0.3)",
  },

  ":focus": {
    backgroundColor: "rgba(81, 42, 204, 0.3)",
  },
}));

export const CountryMenuItem = styling(MenuItem)((props) => ({
  fontFamily: "Inter",
  fontSize: "12px",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  columnGap: "10px",
  rowGap: "24px",
  width: "100%",
  cursor: "pointer",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [props.theme.breakpoints.down("lg")]: {
    rowGap: "16px",
  },
}));

export const DrawerCountryToggleGrid = styling(Grid)((props) => ({
  borderRadius: "8px",
  overflow: "hidden",
  border: `1px solid ${Color.grey}`,
  transition: "height 0.3s ease",
  width: "100%",

  [props.theme.breakpoints.up("tablet")]: {
    width: "350px",
  },
}));

export const TypeformButton = styling(PopupButton)((props) => ({
  border: "none",
  textWrap: "nowrap",
  whiteSpace: "nowrap",
  backgroundColor: "transparent",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  gap: "4px",
  fontFamily: "Inter",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  color: Color.hiredlyPurple,

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.112px",
  },
}));

export const XMarkIconStyled = styling(XMarkIcon)((props) => ({
  width: "20px",
  height: "20px",
}));
