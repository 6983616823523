import Cookies from "js-cookie";
import {
  getExperienceLevelsWithoutRedux,
  getJobTypesWithoutRedux,
  getTracksWithoutRedux,
} from "../redux/actions/job_action";
import { store } from "../redux/stores/store";
import { SHOW_CHAT, SWITCH_COUNTRY_FLAG_KEYNAME } from "./constant";
import { calibrateSalary, stringifyObjValues } from "./data_management";
import { sendTrackingEvent } from "./tracking_management";

const onLinkClicked = (url, router) => (event) => {
  event.stopPropagation();
  event.preventDefault();

  if (event.ctrlKey || event.metaKey) {
    try {
      window.open(url);
    } catch (error) {}
  } else {
    if (url == "/") {
      router.push(url);
    } else {
      router.push(url);
    }
  }
};

const handleRedirectUrl = (type, selectedCountry, slug) => {
  const env = process.env.NEXT_PUBLIC_JSW_ENV;

  let baseUrl = "";

  switch (env) {
    case "dev":
      baseUrl = `https://dev-${selectedCountry}.hiredly.com/${type}/${slug}`;
      break;
    case "staging":
      baseUrl = `https://staging-${selectedCountry}.hiredly.com/${type}/${slug}`;
      break;
    case "prod":
      baseUrl = `https://${selectedCountry}.hiredly.com/${type}/${slug}`;
      break;
    default:
      baseUrl = `/${type}/${slug}`;
  }

  return selectedCountry !== "my" ? baseUrl + "?source=my" : baseUrl;
};

const handleCompanyLinkClicked =
  (company, router, destination, index) => (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (
      typeof index === "number" &&
      index < 8 &&
      router.pathname === "/companies"
    ) {
      sendTrackingEvent({
        event: "CE_click-company-company-list",
        "company-tile-index": index + 1,
        "company-id": company.id,
      });

      Cookies.set("click-source", "companies_page");
    } else if (router.pathname === "/company/[id]") {
      sendTrackingEvent({
        event: "CE_view-company",
      });
    }

    if (router.pathname.includes("/profile")) {
      Cookies.set("click-source", "profile_saved_company");
    }

    // create tracking cookie for viewing company
    Cookies.set("company-id", company.id, { expires: 1 }); // Cookie expires in 1 day

    // open company page in same tab or new tab depending on destination
    const companyUrl = `/company/${company.slug}`;
    if (destination == "new-tab") {
      window.open(companyUrl, "_blank");
    } else {
      if (event.ctrlKey || event.metaKey) {
        try {
          window.open(companyUrl);
        } catch (error) {
          //
        }
      } else {
        router.push(companyUrl);
      }
    }
  };

const generateJobFilterUrl = async (routePath) => {
  const reduxJobsStore = store.getState().jobs;

  const specialisation =
    reduxJobsStore?.tracks ?? (await getTracksWithoutRedux());
  const jobTypes =
    reduxJobsStore?.jobTypes ?? (await getJobTypesWithoutRedux());
  const experienceLevels =
    reduxJobsStore?.experienceLevels ??
    (await getExperienceLevelsWithoutRedux());

  const selectedFilters = stringifyObjValues(
    store.getState().jobs?.jobListFilter
  );

  // Job specialisation
  const selectedSpec = selectedFilters
    ? specialisation?.filter((track) =>
        selectedFilters?.track_ids?.includes(track?.id?.toString())
      )
    : [];

  const selectedSpecSlugs = selectedSpec?.map((spec) => spec.slug);

  const concatSpecSlug =
    selectedSpecSlugs &&
    Array.isArray(selectedSpecSlugs) &&
    selectedSpecSlugs.length > 0
      ? selectedSpecSlugs.join("+")
      : "";

  // Job type
  const selectedJobType = selectedFilters
    ? jobTypes?.filter((type) =>
        selectedFilters?.job_type_ids?.includes(type?.id?.toString())
      )
    : [];

  const selectedJobTypeTitle = selectedJobType?.map((type) => type.title);

  const concatJobTypeTitle =
    selectedJobTypeTitle &&
    Array.isArray(selectedJobTypeTitle) &&
    selectedJobTypeTitle.length > 0
      ? selectedJobTypeTitle.join("+")
      : "";

  // Job state region
  const concatStateRegion = selectedFilters
    ? Array.isArray(selectedFilters?.state_region_names) &&
      selectedFilters?.state_region_names?.length > 0
      ? selectedFilters?.state_region_names
          ?.map((state) => state.replaceAll(" ", "-"))
          .join("+")
      : selectedFilters?.state_region_names
      ? selectedFilters?.state_region_names.replaceAll(" ", "-")
      : ""
    : "";

  // Job search keyword
  const searchKeyword = selectedFilters
    ? selectedFilters?.keyword
      ? selectedFilters?.keyword?.toString().toLowerCase().replaceAll(" ", "-")
      : ""
    : "";

  // Job experience levels
  const selectedJobExpLvl = selectedFilters
    ? experienceLevels?.filter((level) =>
        selectedFilters?.experience_ids?.includes(level.id?.toString())
      )
    : [];

  const selectedJobExpLvlTitle = selectedJobExpLvl?.map((level) =>
    level.title?.toString().replaceAll(" ", "-")
  );

  const concatExpLvlTitle = selectedJobExpLvlTitle
    ? Array.isArray(selectedJobExpLvlTitle) &&
      selectedJobExpLvlTitle?.length > 0
      ? selectedJobExpLvlTitle?.join("+")
      : ""
    : "";

  const salaryStringify = selectedFilters
    ? selectedFilters?.expected_salary
      ? calibrateSalary(parseInt(selectedFilters?.expected_salary))
      : ""
    : "";

  const urlObj = new URLSearchParams();

  searchKeyword && urlObj.append("keyword", searchKeyword);
  concatSpecSlug && urlObj.append("specialization", concatSpecSlug);
  concatStateRegion && urlObj.append("state", concatStateRegion);
  concatJobTypeTitle && urlObj.append("jobtype", concatJobTypeTitle);
  concatExpLvlTitle && urlObj.append("explevel", concatExpLvlTitle);
  salaryStringify && urlObj.append("salary", salaryStringify);

  const cleanUrlParam = urlObj.toString().trim().toLowerCase();

  const joinUrlAndParams =
    cleanUrlParam.length > 0 ? `${routePath}?${cleanUrlParam}` : routePath;

  return joinUrlAndParams;
};

// Next.js useRouter() object
function handleSwitchCountry({ country, router, openNewTab }) {
  const countryUrls = {
    my: {
      prod: "https://my.hiredly.com",
      staging: "https://staging-my.hiredly.com",
      dev: "https://dev-my.hiredly.com",
    },
    sg: {
      prod: "https://sg.hiredly.com",
      staging: "https://staging-sg.hiredly.com",
      dev: "https://dev-sg.hiredly.com",
    },
  };

  // Get the replacement URL based on the hostname
  const replacementUrl = countryUrls[country][process.env.NEXT_PUBLIC_JSW_ENV];

  if (replacementUrl) {
    Cookies.set(SWITCH_COUNTRY_FLAG_KEYNAME, true, {
      // make sure this is dot hiredly dot com because we need all subdomain to access this value.
      domain: ".hiredly.com",
      path: "/",
    });

    // this is triggered when ChatWrapper.jsx triggers the function
    // to pass in param to automatically open chat when visiting another country
    if (openNewTab) {
      const searchParam = new URLSearchParams();
      searchParam.append(SHOW_CHAT, true);

      return window.open(`${replacementUrl}/?${searchParam}`, "_blank");
    }

    router.replace(replacementUrl);
  } else {
    router.reload();
  }
}

export {
  generateJobFilterUrl,
  handleCompanyLinkClicked,
  handleRedirectUrl,
  handleSwitchCountry,
  onLinkClicked,
};
